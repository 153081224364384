export default [
    {
        name: 'PaymentList',
        path: 'payments',
        component: () => import('./views/PaymentList.vue'),
        meta: {
            title: 'Payments',
            perms: 'leads',
        },
    },
    {
        name: 'PendingPaymentList',
        path: 'payments/pending',
        component: () => import('./views/PendingPaymentList.vue'),
        meta: {
            title: 'Pending Payments',
            perms: 'leads',
        },
    },
    {
        name: 'PendingPaymentCalendar',
        path: 'payments/pending/calendar',
        component: () => import('./views/PendingPaymentCalendar.vue'),
        meta: {
            title: 'Pending Payments Calendar',
            perms: 'leads',
        },
    },
    {
        name: 'TransferList',
        path: 'payments/transfers',
        component: () => import('./views/TransferList.vue'),
        meta: {
            title: 'Transfers',
            perms: 'leads',
        },
    },
    {
        name: 'TransferCreate',
        path: 'payments/transfers/create',
        component: () => import('./views/TransferForm.vue'),
        meta: {
            title: 'Create Transfer',
            perms: 'leads',
        },
    },
    {
        name: 'TransferUpdate',
        path: 'payments/transfers/:transfer',
        component: () => import('./views/TransferForm.vue'),
        meta: {
            title: 'Edit Transfer',
            perms: 'leads',
        },
    },
]
